import { useAuthStore } from '~/stores/auth'
import { useRuntimeConfig, defineNuxtPlugin } from '#app'

import AcquisitionRepository from '~/repositories/AcquisitionRepository'
import ActivityRepository from '~/repositories/ActivityRepository'
import BankRepository from '~/repositories/BankRepository'
import ClientStepRepository from '~/repositories/ClientStepRepository'
import CommentRepository from '~/repositories/CommentRepository'
import ContactEventRepository from '~/repositories/ContactEventRepository'
import ContactRatingRepository from '~/repositories/ContactRatingRepository'
import ContactRepository from '~/repositories/ContactRepository'
import FolderRepository from '~/repositories/FolderRepository'
import MediaRepository from '~/repositories/MediaRepository'
import PartnerCompanyRepository from '~/repositories/PartnerCompanyRepository'
import ProductRepository from '~/repositories/ProductRepository'
import RegionRepository from '~/repositories/RegionRepository'
import SectorRepository from '~/repositories/SectorRepository'
import SettingsRepository from '~/repositories/SettingsRepository'
import StatsRepository from '~/repositories/StatsRepository'
import TaskRepository from '~/repositories/TaskRepository'
import UserRepository from '~/repositories/UserRepository'

export default defineNuxtPlugin(({ $pinia }) => {
    const auth = useAuthStore($pinia)
    const config = useRuntimeConfig()

    return {
        provide: {
            acquisitionRepository: new AcquisitionRepository(auth, config),
            activityRepository: new ActivityRepository(auth, config),
            bankRepository: new BankRepository(auth, config),
            folderRepository: new FolderRepository(auth, config),
            clientStepRepository: new ClientStepRepository(auth, config),
            commentRepository: new CommentRepository(auth, config),
            contactEventRepository: new ContactEventRepository(auth, config),
            contactRatingRepository: new ContactRatingRepository(auth, config),
            contactRepository: new ContactRepository(auth, config),
            mediaRepository: new MediaRepository(auth, config),
            partnerCompanyRepository: new PartnerCompanyRepository(auth, config),
            productRepository: new ProductRepository(auth, config),
            regionRepository: new RegionRepository(auth, config),
            sectorRepository: new SectorRepository(auth, config),
            settingsRepository: new SettingsRepository(auth, config),
            statsRepository: new StatsRepository(auth, config),
            taskRepository: new TaskRepository(auth, config),
            userRepository: new UserRepository(auth, config)
        }
    }
})
