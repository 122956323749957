import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/home/ploi/app.kray.eu/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/home/ploi/app.kray.eu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/home/ploi/app.kray.eu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/home/ploi/app.kray.eu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/home/ploi/app.kray.eu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/home/ploi/app.kray.eu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/home/ploi/app.kray.eu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/home/ploi/app.kray.eu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/home/ploi/app.kray.eu/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/ploi/app.kray.eu/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/home/ploi/app.kray.eu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8 from "/home/ploi/app.kray.eu/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_VW0S5L6gti_L2lJP5Fh8KfD6_3GK52P9spdjsc0v3y4 from "/home/ploi/app.kray.eu/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import globalMixin_WJZBEQjWKrqmrC5wsMFu_3iV_71DxiIw7A8rfV2yCQE from "/home/ploi/app.kray.eu/plugins/globalMixin.js";
import markdownParser_MDd9WZeB3GYdW25ZtG8MPIYp_U8eUiM5G7eWJvX5Rv4 from "/home/ploi/app.kray.eu/plugins/markdownParser.js";
import pinia_2z8Ip_jWBRJR11oARwTuXOuYmKme2JpGLevDuCPfHcc from "/home/ploi/app.kray.eu/plugins/pinia.js";
import repository_0ajtmn_Xs5W1uywLEI42enEO4ZYfhp6ze4QVT7DnTuQ from "/home/ploi/app.kray.eu/plugins/repository.js";
import ui_1ROpdlHvMwEuBmv33_5jcPPy1OlXGVQs4zVQ3phRIFU from "/home/ploi/app.kray.eu/plugins/ui.js";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8,
  plugin_VW0S5L6gti_L2lJP5Fh8KfD6_3GK52P9spdjsc0v3y4,
  globalMixin_WJZBEQjWKrqmrC5wsMFu_3iV_71DxiIw7A8rfV2yCQE,
  markdownParser_MDd9WZeB3GYdW25ZtG8MPIYp_U8eUiM5G7eWJvX5Rv4,
  pinia_2z8Ip_jWBRJR11oARwTuXOuYmKme2JpGLevDuCPfHcc,
  repository_0ajtmn_Xs5W1uywLEI42enEO4ZYfhp6ze4QVT7DnTuQ,
  ui_1ROpdlHvMwEuBmv33_5jcPPy1OlXGVQs4zVQ3phRIFU
]