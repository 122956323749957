
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexd42iPAZV8HO1WqMYdtd8S8lhHEnHGkXae2p6DTJnLS0Meta } from "/home/ploi/app.kray.eu/pages/index.vue?macro=true";
import { default as logoutSwBiEGlW3yk_45A4g37KLSzMJh2JF8ZYLx7eQUiD8_INQMeta } from "/home/ploi/app.kray.eu/pages/logout.vue?macro=true";
import { default as indexUs5Sy0pLzP5X623IykacllZNLTIklBLSGYvLWZ_9O5UMeta } from "/home/ploi/app.kray.eu/pages/Bank/index.vue?macro=true";
import { default as indexmBq8NlV_45gY6r03_456tWu68WepLeGfI61t7qwh1914tNYMeta } from "/home/ploi/app.kray.eu/pages/Task/index.vue?macro=true";
import { default as createbADVySuBMdXrTI_45LcAQTiRqSJNHYf5WjF8nck60_45kEAMeta } from "/home/ploi/app.kray.eu/pages/Bank/create.vue?macro=true";
import { default as indexIDoF2CFBBpXZbyx7wJh_4kW1CexTmnQOCxBZBwZM7ysMeta } from "/home/ploi/app.kray.eu/pages/Login/index.vue?macro=true";
import { default as token8XYHeecobQcMTje5ztijawCWrFTWGmOM_IlcUVopJqcMeta } from "/home/ploi/app.kray.eu/pages/Login/token.vue?macro=true";
import { default as index5D7jPIghMMqGscxo47mbnMjW1UWom9ej4Er55EiYu8sMeta } from "/home/ploi/app.kray.eu/pages/Broker/index.vue?macro=true";
import { default as index0pZXc_45XqMjnjz7TYzmf9J7RngfFCPquPGGMKG8Z6Z7IMeta } from "/home/ploi/app.kray.eu/pages/Client/index.vue?macro=true";
import { default as createvVlX7Uy_45JJWLehncp5UN6dinEiFteRYEiQeBzaNhaycMeta } from "/home/ploi/app.kray.eu/pages/Broker/create.vue?macro=true";
import { default as createPW5Z8FSMQevaCQLrxqCtjywUimhDu3SXwn6qPCYOjTMMeta } from "/home/ploi/app.kray.eu/pages/Client/create.vue?macro=true";
import { default as indexTM6Nb6y2WHnYvxwVZeFo2mkJ3GvPJq_aBQmMEvww9eIMeta } from "/home/ploi/app.kray.eu/pages/Contact/index.vue?macro=true";
import { default as index4LH29LV860Fj5bY_45gLgxHHHX_XvRaieBjj3HTPqvss8Meta } from "/home/ploi/app.kray.eu/pages/Product/index.vue?macro=true";
import { default as createY_45PUbpgDVj719d7BeHznlGCj4U7pKKSRlnYqIqWpsWwMeta } from "/home/ploi/app.kray.eu/pages/Product/create.vue?macro=true";
import { default as indexYfF6rEC3YN76iFuKf5Q90sgdJDUc5_45TDIEgbtRYhctcMeta } from "/home/ploi/app.kray.eu/pages/Settings/index.vue?macro=true";
import { default as index6sap9CcvN01xZLy0AAW3A9CGZInCL6v6g03gVJChCr0Meta } from "/home/ploi/app.kray.eu/pages/Bank/[id]/index.vue?macro=true";
import { default as index_D25SMudNkhv7QY6F14el4CwsvmjsozvvZDnjo3f_45KkMeta } from "/home/ploi/app.kray.eu/pages/Documents/index.vue?macro=true";
import { default as indexdx6hFCx5r3c6PLyWlpVlJxoxVKWm7nM9zrCYi4AxDWUMeta } from "/home/ploi/app.kray.eu/pages/Timesheet/index.vue?macro=true";
import { default as editkuQEqpxFmae5lnJ_xMo7THBK2MYc_jithFlwwNhpzGkMeta } from "/home/ploi/app.kray.eu/pages/Broker/[id]/edit.vue?macro=true";
import { default as editS8hH4QFQ3WAOrlvnHU9ZdBLWWYHZKqBUqn8H3ei_45_45KAMeta } from "/home/ploi/app.kray.eu/pages/Client/[id]/edit.vue?macro=true";
import { default as indexS39icuzU_45_jLMgYQIxLhioq57hVlsCrSb1cCg7IzA1YMeta } from "/home/ploi/app.kray.eu/pages/Acquisition/index.vue?macro=true";
import { default as indexbAy47JXMK7W4pbsqFfELQg4PNC2saJyFSx8ZU5FNAJ8Meta } from "/home/ploi/app.kray.eu/pages/Broker/[id]/index.vue?macro=true";
import { default as indexNfNYajxYOP5NhsxBEBsgkdyJHlIvgRNqxQRJ6M_454UhEMeta } from "/home/ploi/app.kray.eu/pages/Client/[id]/index.vue?macro=true";
import { default as indexFqbBSQ6kT_WZFyzPcCqcoyaa1p5Rk21Q1qBQucYgjCAMeta } from "/home/ploi/app.kray.eu/pages/Prospection/index.vue?macro=true";
import { default as indexu1pKKGInhNgH_45ZghvhvYK7AQ_45UZRxgr8WB3wnxvfvV0Meta } from "/home/ploi/app.kray.eu/pages/Product/[id]/index.vue?macro=true";
import { default as indexVfjgnLfNwPqUvPVjgDIUPFl48q9iqT4yHhVSnwiPa2IMeta } from "/home/ploi/app.kray.eu/pages/FunderCompany/index.vue?macro=true";
import { default as create_fICVkCJxCHsjEYhJ0CSz1ML1Ng6Z8bLIRtvM81yRT0Meta } from "/home/ploi/app.kray.eu/pages/FunderCompany/create.vue?macro=true";
import { default as indexXGmySkUA4OkF8u0y9O2fHCgO9VrV6OQ9wvfmeBvCFigMeta } from "/home/ploi/app.kray.eu/pages/PartnerCompany/index.vue?macro=true";
import { default as createWElbW09h_hmsIj_45rUbIWQo7lAeBi8bTVceCfrWxwsHgMeta } from "/home/ploi/app.kray.eu/pages/PartnerCompany/create.vue?macro=true";
import { default as indexplgZPbkHEhqRKjJvIXbEQmjeJVV4XG7Rb3H4NNpU3mEMeta } from "/home/ploi/app.kray.eu/pages/Bank/[id]/contact/index.vue?macro=true";
import { default as editMxwWifIjXvgMREKwusxfW_45t0aLsF6HltJI_45j8WSm_45BcMeta } from "/home/ploi/app.kray.eu/pages/FunderCompany/[id]/edit.vue?macro=true";
import { default as indexeXJdiSM32gZxtcB51WVXrmnoxPCkmF0SeAiR_45OeEf6sMeta } from "/home/ploi/app.kray.eu/pages/FunderCompany/[id]/index.vue?macro=true";
import { default as editAUwXG66vZMTg_4Lo5147_45lDD7Lhc8vNA9fnMC94wY9cMeta } from "/home/ploi/app.kray.eu/pages/PartnerCompany/[id]/edit.vue?macro=true";
import { default as editO_45Js_sLfiF0Tj_Vq4vdXuGsQCXjTZH7eeEFw8aVmhEwMeta } from "/home/ploi/app.kray.eu/pages/Contact/[contact_id]/edit.vue?macro=true";
import { default as indexPs8qRdvJbyT2nUpkM1lZMb_oss2cbkepr0aSKdBKNzcMeta } from "/home/ploi/app.kray.eu/pages/PartnerCompany/[id]/index.vue?macro=true";
import { default as indexYSEpSRg9gHBXtNmMcfKN3qhaqEp7lG9V2foXLi_ID2wMeta } from "/home/ploi/app.kray.eu/pages/Contact/[contact_id]/index.vue?macro=true";
import { default as indexR1nr46x_458PUCyem7JSc8QJIJejF1HTGKJJy0G8sc2TEMeta } from "/home/ploi/app.kray.eu/pages/Product/[id]/contact/index.vue?macro=true";
import { default as indexSe0k96CXAQWA342Ds_45hvYiBl_TObOK9DLzqEM_SiAmUMeta } from "/home/ploi/app.kray.eu/pages/FunderCompany/[id]/contact/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexd42iPAZV8HO1WqMYdtd8S8lhHEnHGkXae2p6DTJnLS0Meta || {},
    component: () => import("/home/ploi/app.kray.eu/pages/index.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutSwBiEGlW3yk_45A4g37KLSzMJh2JF8ZYLx7eQUiD8_INQMeta || {},
    component: () => import("/home/ploi/app.kray.eu/pages/logout.vue")
  },
  {
    name: "Bank",
    path: "/Bank",
    component: () => import("/home/ploi/app.kray.eu/pages/Bank/index.vue")
  },
  {
    name: "Task",
    path: "/Task",
    component: () => import("/home/ploi/app.kray.eu/pages/Task/index.vue")
  },
  {
    name: "Bank-create",
    path: "/Bank/create",
    component: () => import("/home/ploi/app.kray.eu/pages/Bank/create.vue")
  },
  {
    name: "Login",
    path: "/Login",
    meta: indexIDoF2CFBBpXZbyx7wJh_4kW1CexTmnQOCxBZBwZM7ysMeta || {},
    component: () => import("/home/ploi/app.kray.eu/pages/Login/index.vue")
  },
  {
    name: "Login-token",
    path: "/Login/token",
    meta: token8XYHeecobQcMTje5ztijawCWrFTWGmOM_IlcUVopJqcMeta || {},
    component: () => import("/home/ploi/app.kray.eu/pages/Login/token.vue")
  },
  {
    name: "Broker",
    path: "/Broker",
    component: () => import("/home/ploi/app.kray.eu/pages/Broker/index.vue")
  },
  {
    name: "Client",
    path: "/Client",
    component: () => import("/home/ploi/app.kray.eu/pages/Client/index.vue")
  },
  {
    name: "Broker-create",
    path: "/Broker/create",
    component: () => import("/home/ploi/app.kray.eu/pages/Broker/create.vue")
  },
  {
    name: "Client-create",
    path: "/Client/create",
    component: () => import("/home/ploi/app.kray.eu/pages/Client/create.vue")
  },
  {
    name: "Contact",
    path: "/Contact",
    component: () => import("/home/ploi/app.kray.eu/pages/Contact/index.vue")
  },
  {
    name: "Product",
    path: "/Product",
    component: () => import("/home/ploi/app.kray.eu/pages/Product/index.vue")
  },
  {
    name: "Product-create",
    path: "/Product/create",
    component: () => import("/home/ploi/app.kray.eu/pages/Product/create.vue")
  },
  {
    name: "Settings",
    path: "/Settings",
    component: () => import("/home/ploi/app.kray.eu/pages/Settings/index.vue")
  },
  {
    name: "Bank-id",
    path: "/Bank/:id()",
    component: () => import("/home/ploi/app.kray.eu/pages/Bank/[id]/index.vue")
  },
  {
    name: "Documents",
    path: "/Documents",
    component: () => import("/home/ploi/app.kray.eu/pages/Documents/index.vue")
  },
  {
    name: "Timesheet",
    path: "/Timesheet",
    component: () => import("/home/ploi/app.kray.eu/pages/Timesheet/index.vue")
  },
  {
    name: "Broker-id-edit",
    path: "/Broker/:id()/edit",
    component: () => import("/home/ploi/app.kray.eu/pages/Broker/[id]/edit.vue")
  },
  {
    name: "Client-id-edit",
    path: "/Client/:id()/edit",
    component: () => import("/home/ploi/app.kray.eu/pages/Client/[id]/edit.vue")
  },
  {
    name: "Acquisition",
    path: "/Acquisition",
    component: () => import("/home/ploi/app.kray.eu/pages/Acquisition/index.vue")
  },
  {
    name: "Broker-id",
    path: "/Broker/:id()",
    component: () => import("/home/ploi/app.kray.eu/pages/Broker/[id]/index.vue")
  },
  {
    name: "Client-id",
    path: "/Client/:id()",
    component: () => import("/home/ploi/app.kray.eu/pages/Client/[id]/index.vue")
  },
  {
    name: "Prospection",
    path: "/Prospection",
    component: () => import("/home/ploi/app.kray.eu/pages/Prospection/index.vue")
  },
  {
    name: "Product-id",
    path: "/Product/:id()",
    component: () => import("/home/ploi/app.kray.eu/pages/Product/[id]/index.vue")
  },
  {
    name: "FunderCompany",
    path: "/FunderCompany",
    component: () => import("/home/ploi/app.kray.eu/pages/FunderCompany/index.vue")
  },
  {
    name: "FunderCompany-create",
    path: "/FunderCompany/create",
    component: () => import("/home/ploi/app.kray.eu/pages/FunderCompany/create.vue")
  },
  {
    name: "PartnerCompany",
    path: "/PartnerCompany",
    component: () => import("/home/ploi/app.kray.eu/pages/PartnerCompany/index.vue")
  },
  {
    name: "PartnerCompany-create",
    path: "/PartnerCompany/create",
    component: () => import("/home/ploi/app.kray.eu/pages/PartnerCompany/create.vue")
  },
  {
    name: "Bank-id-contact",
    path: "/Bank/:id()/contact",
    component: () => import("/home/ploi/app.kray.eu/pages/Bank/[id]/contact/index.vue")
  },
  {
    name: "FunderCompany-id-edit",
    path: "/FunderCompany/:id()/edit",
    component: () => import("/home/ploi/app.kray.eu/pages/FunderCompany/[id]/edit.vue")
  },
  {
    name: "FunderCompany-id",
    path: "/FunderCompany/:id()",
    component: () => import("/home/ploi/app.kray.eu/pages/FunderCompany/[id]/index.vue")
  },
  {
    name: "PartnerCompany-id-edit",
    path: "/PartnerCompany/:id()/edit",
    component: () => import("/home/ploi/app.kray.eu/pages/PartnerCompany/[id]/edit.vue")
  },
  {
    name: "Contact-contact_id-edit",
    path: "/Contact/:contact_id()/edit",
    component: () => import("/home/ploi/app.kray.eu/pages/Contact/[contact_id]/edit.vue")
  },
  {
    name: "PartnerCompany-id",
    path: "/PartnerCompany/:id()",
    component: () => import("/home/ploi/app.kray.eu/pages/PartnerCompany/[id]/index.vue")
  },
  {
    name: "Contact-contact_id",
    path: "/Contact/:contact_id()",
    component: () => import("/home/ploi/app.kray.eu/pages/Contact/[contact_id]/index.vue")
  },
  {
    name: "Product-id-contact",
    path: "/Product/:id()/contact",
    component: () => import("/home/ploi/app.kray.eu/pages/Product/[id]/contact/index.vue")
  },
  {
    name: "FunderCompany-id-contact",
    path: "/FunderCompany/:id()/contact",
    component: () => import("/home/ploi/app.kray.eu/pages/FunderCompany/[id]/contact/index.vue")
  }
]